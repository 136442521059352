<template>
  <div class="dashboard">
    <!-- nothing to show here, only handle onboarding state based routing -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  beforeMount() {
    // check routes for next step
    const possibleRouteNames = [
      'onboarding_bank_account',
      'onboarding_tax_data',
      'onboarding_good_conduct_certificate',
      'onboarding_proof_of_insurance',
      'onboarding_qundo_verification',
      'onboarding_appointment'
    ]
    let nextRouteName = possibleRouteNames[0]
    for (const item of possibleRouteNames) {
      // special handling for qundo page blocked after completed
      if (item === 'onboarding_qundo_verification' && this.current_user.qundo_verification?.state === 1) {
        nextRouteName = 'onboarding_appointment'
        continue
      }
      // check if current route is permitted to be visited, otherwise go to last permitted one
      if (!this.$hasRoutePermission(this.$router, item, this.current_user)) break
      nextRouteName = item
    }
    this.$router.push({name: nextRouteName})
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters("user", ["current_user"])
  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>


